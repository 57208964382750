<template>
  <Row id="PageContent">
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="16">
              <Col :span="6">
                <FormItem label="ID" name="id">
                  <Input :placeholder="$t('authority.validate.required.id_aut')"
                         v-model:value="searchForm.idBalLog"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('balanceLog.table.id_use')" name="userAccount">
                  <Input :placeholder="$t('balanceLog.table.id_use')" v-model:value="searchForm.userAccount"></Input>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('balanceLog.table.type')" name="type">
                  <Select
                      v-model:value="searchForm.type"
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%;"
                      :options="typeList"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('balanceLog.table.status')" name="status">
                  <Select
                      v-model:value="searchForm.status"
                      :placeholder="$t('public.pleaseSelect')"
                      style="width: 100%;"
                      :options="statusList"
                  ></Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('balanceLog.table.log_time')" name="log_time">
                  <RangePicker style="width: 100%" v-model:value="searchForm.valueTime"/>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('balanceLog.table.payment_method')" name="payType">
                  <Select
                      v-model:value="searchForm.payType"
                      :placeholder="$t('balanceLog.table.payment_method')"
                      :options="payTypeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                      allowClear
                  >
                  </Select>
                </FormItem>
              </Col>
              <Col :span="6">
                <FormItem :label="$t('order.table.pay_type')" name="paymentMethod">
                  <Input :placeholder="$t('order.validate.pay_type')" v-model:value="searchForm.paymentMethod"></Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row style="margin-bottom: 5px">
        <Col :span="8">
          <Button type="primary" @click="exportToExecl">{{ $t('public.name.Export') }}</Button>
          <span>&nbsp;</span>
          <Button type="primary" @click="showRechargePackageModal">{{ $t('balanceLog.rechargePackage') }}</Button>
        </Col>
        <Col :span="8" :offset="8" style="text-align: right;">
          <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>
          <span>&nbsp;</span>
          <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>
        </Col>
      </Row>
      <div>
        <Table :columns="columns" :dataSource="list"
               :scroll="{x: 1500}"
               rowKey="idBalLog"
               :loading="loading" :pagination="tablePagination" @change="chang_page">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <span>
                {{ $t("public.table.action") }}
                <!-- <Button v-if="checkPermission('BalanceLogManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button> -->
              </span>
            </template>
          </template>

          <template #bodyCell="{column, record}">
            <template v-if="column.dataIndex === 'action'">
              <div class="pc-display">
                <a @click="detail(record)">
                  {{ $t("public.name.detail") }}
                </a>
                <Divider type="vertical"/>
                <Dropdown :trigger="['click']" @click.prevent>
                  <a>
                    {{ $t("public.name.setting") }}
                    <DownOutlined/>
                  </a>
                  <template #overlay>
                    <Menu>
                      <div v-if="record.type === 9">
                        <MenuItem>
                          <Button type="default" size="small"
                                  @click="cancelFines(record)"> {{ $t("balanceLog.name.cancelFines") }}
                          </Button>
                        </MenuItem>
                      </div>
                      <div v-else>
                        <MenuItem v-if="record.idBalLog !== undefined && record.idBalLog !== null">
                          <Button v-if="record.type === 5 || record.type === 6 || record.type === 7" type="default" size="small"
                                  @click="detailUserPlan(record.idBalLog)">{{ $t("userPlan.name.title") }}
                          </Button>
                        </MenuItem>
                        <MenuItem v-if="record.type === 14">
                          <Button type="default" size="small"
                                  @click="exportStatement(record.idBalLog, 'xlsx')"> {{ $t("public.name.Export") + ' xlsx'}}
                          </Button>
                        </MenuItem>
                        <MenuItem v-if="record.type === 14">
                          <Button type="default" size="small"
                                  @click="exportStatement(record.idBalLog, 'pdf')"> {{ $t("public.name.Export") + ' pdf'}}
                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button type="primary" size="small" @click="query(record)">
                            {{$t('public.name.query')}}
                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('BalanceLogManage.Edit')" type="primary" size="small" @click="refund(record, 'refund')">
                            {{$t('public.name.refund')}}
                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button v-if="checkPermission('BalanceLogManage.Edit')" type="primary" size="small" @click="refund(record), 'revoke'">
                            {{$t('public.name.revoke')}}
                          </Button>
                        </MenuItem>
                      </div>
                    </Menu>
                  </template>
                </Dropdown>
              </div>
            </template>
          </template>
        </Table>
      </div>
    </Col>
    <BalanceLogDetail ref="refBalanceLogDetail"></BalanceLogDetail>
    <BalUserPlanDetail ref="refBalUserPlanDetail"></BalUserPlanDetail>
    <BalRefundEdit ref="refBalRefundEdit"></BalRefundEdit>
    <BalQuery ref="refBalQuery"></BalQuery>
    <CancelFines ref="refCancelFines"></CancelFines>
    <RechargePackageModal ref="refRechargePackageModal"></RechargePackageModal>
  </Row>
</template>
<script>
export default {
  name: "BalanceLogManage",
}
</script>
<script setup>
import {createVNode, reactive, ref, toRefs} from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  message,
  RangePicker,
  Divider,
  Dropdown,
  Menu,
  MenuItem
} from "ant-design-vue";
import {DownOutlined, ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {BalanceLogList, BalanceLogDelete, BalanceLogExportToExcel, BalanceLogExportStatement} from "@/api/balanceLog";
import BalanceLogDetail from "./BalanceLogDetail";
import BalUserPlanDetail from "./BalUserPlanDetail";
import BalRefundEdit from "./BalRefundEdit";
import BalQuery from "./BalQuery";
import CancelFines from "./CancelFines";
import RechargePackageModal from "./RechargePackageModal";
import dayjs from "dayjs";
import {useI18n} from "vue-i18n"
import {useStore} from "vuex";

const store = useStore()
const i18n = useI18n()

const refBalanceLogDetail = ref()
const refBalUserPlanDetail = ref()
const refBalRefundEdit = ref()
const refBalQuery = ref()
const refCancelFines = ref()
const refRechargePackageModal = ref()

let loading = ref(false)
let list = ref([])

const typeList = [
  {value: 1, label: i18n.t("public.consumeType.0")},
  {value: 2, label: i18n.t("public.consumeType.1")},
  {value: 3, label: i18n.t("public.consumeType.2")},
  {value: 4, label: i18n.t("public.consumeType.3")},
  {value: 5, label: i18n.t("public.consumeType.4")},
  {value: 6, label: i18n.t("public.consumeType.5")},
  {value: 7, label: i18n.t("public.consumeType.6")},
  {value: 8, label: i18n.t("public.consumeType.7")},
  {value: 9, label: i18n.t("public.consumeType.8")},
  {value: 10, label: i18n.t("public.consumeType.9")},
  {value: 11, label: i18n.t("public.consumeType.10")},
  {value: 12, label: i18n.t("public.consumeType.11")},
  {value: 13, label: i18n.t("public.consumeType.12")},
  {value: 14, label: i18n.t("public.consumeType.13")},
]
const statusList = [
  {value: 1, label: i18n.t("balanceLog.status.1")},
  {value: 2, label: i18n.t("balanceLog.status.2")},
  {value: 3, label: i18n.t("balanceLog.status.3")},
  {value: 4, label: i18n.t("balanceLog.status.4")},
]
const payTypeList = [
  {value: 1, label: 'Online Pay'},
  {value: 2, label: 'Balance'}
]
const columns = [
  {
    title: i18n.t("balanceLog.table.id_bal_log"),
    width: 48,
    fixed: "left",
    dataIndex: "idBalLog",
    key: "idBalLog"
  },
  {title: 'No', width: 90, dataIndex: "orderNo", key: "orderNo"},
  {title: i18n.t("balanceLog.table.id_use"), width: 90, dataIndex: "userAccount", key: "userAccount"},
  {title: i18n.t("user.table.phone"), width: 120, dataIndex: "userPhone", key: "userPhone"},
  {
    title: i18n.t("balanceLog.table.type"),
    width: 80,
    dataIndex: "type",
    key: "type",
    customRender: ({text}) => {
      return typeList.find(item => item.value === text).label
    }
  },
  {title: i18n.t("balanceLog.table.amount"), width: 80, dataIndex: "amount", key: "amount", customRender: ({text, record, index, column}) => {
    const type = record.type;
    let consumption = [2, 5, 7, 9];
    return (consumption.includes(type)) ? "-" + text : text
  }},
  {
    title: i18n.t("balanceLog.table.log_time"),
    width: 110,
    dataIndex: "createTime",
    key: "createTime",
    customRender: ({text}) => {
      return dayjs(text).format('YYYY-MM-DD HH:mm:ss')
    }
  },

  {
    title: i18n.t("balanceLog.table.status"),
    width: 80,
    dataIndex: "status",
    key: "status",
    customRender: ({text}) => {
      return statusList.find(item => item.value === text).label
    }
  },
  // {title: i18n.t("balanceLog.table.tenant_key"), width: 80, dataIndex: "tenantKey", key: "tenantKey"},
  {key: "action", width: 100, fixed: "right", dataIndex: "action"},
]

const data = reactive({
  searchForm: {},
  tablePagination: {
    pageSizeOptions: ["10", "20", "50", "100"],
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
  },
})
const {searchForm, tablePagination} = toRefs(data)

function chang_page(pagination) {
  tablePagination.value = pagination;
  get_list();
}

function get_list() {
  loading.value = true;
  const api = new BalanceLogList();

  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }

  const getData = {
    page: tablePagination.value.current,
    limit: tablePagination.value.pageSize,
    ...args
  }
  api.get(getData).then((response) => {
    loading.value = false;
    if (0 === parseInt(response.data.code)) {
      const dataTmp = response.data.data;
      tablePagination.value.current = parseInt(dataTmp.current);
      tablePagination.value.pageSize = parseInt(dataTmp.pageSize);
      tablePagination.value.total = parseInt(dataTmp.total);
      list.value = dataTmp.data;
    } else {
      list.value = [];
    }
  });
}

function add() {
}

function detail(obj) {
  refBalanceLogDetail.value?.detailShow(obj);
}

function cancelFines(obj) {
  refCancelFines.value?.editShow(obj);
}

function detailUserPlan(idBalLog) {
  refBalUserPlanDetail.value?.detailShow(idBalLog);
}

// 导出月结单
function exportStatement(idBalLog, exportType) {
  loading.value = true
  const api = new BalanceLogExportStatement()
  api.getDownLoad({idBalLog: idBalLog, exportType: exportType}).then(() => {}).finally(() => {
    loading.value = false
  })
}

function edit(obj) {
}
// 查询付款信息
function query(obj) {
  refBalQuery.value?.editShow(obj)
}
// 系统退款
function refund(obj, t) {
  refBalRefundEdit.value?.editShow(obj, t)
}

function del(idBalLog) {
  Modal.confirm({
    title: i18n.t("public.name.are_you_sure_delete") + "?",
    content: i18n.t("public.name.action_cannot_resumed"),
    icon: createVNode(ExclamationCircleOutlined),
    okText: i18n.t("public.name.yes"),
    okType: "danger",
    cancelText: i18n.t("public.name.no"),
    onOk: () => {
      const api = new BalanceLogDelete();
      api.post({idBalLog: idBalLog}).then((response) => {
        if (0 === parseInt(response.data.code)) {
          get_list();
          message.success(i18n.t("succeedMsg.0"));
        } else {
          if (response.data.code !== undefined) {
            message.error(i18n.t("errorMsg." + response.data.code));
          }
        }
      });
    },
  });
}

function search_submit() {
  tablePagination.value.current = 1
  get_list()
}

function search_reset() {
  searchForm.value = {}
  get_list()
}

function exportToExecl() {
  loading.value = true
  const args = {
    ...searchForm.value
  }
  if (args.valueTime) {
    args.inTime = dayjs(args.valueTime[0]).format("YYYY-MM-DD")
    args.toTime = dayjs(args.valueTime[1]).format("YYYY-MM-DD")
    delete args.valueTime
  }

  const api = new BalanceLogExportToExcel()
  api.getDownLoad(args).then(() => {
  }).finally(() => {
    loading.value = false
  })
}


function showRechargePackageModal() {
  refRechargePackageModal.value?.detailShow()
}


// 设置页标题
store.commit("setPageTitle", i18n.t('nav.name.BalanceLogManage'));
get_list();

defineExpose({
  get_list
})
</script>

<style lang="less">
@import url("style.less");
</style>
