import {Axios, Base} from "@/api/Base"

const api = "balanceLog"

export class BalanceLogAdd extends Base {
    path = api + "/add"
}

export class BalanceLogDelete extends Base {
    path = api + "/delete"
}

export class BalanceLogList extends Base {
    path = api + "/list"
}

export class BalanceLogEdit extends Base {
    path = api + "/edit"
}

// 导出Execl
export class BalanceLogExportToExcel extends Base {
    path = api + "/exportToExcel"
}

export class BalanceLogGetInfo extends Base {
    path = api + "/getInfo"
}
// 后台退款、扣款
export class BalanceAddInfoByUserId extends Base {
    path = api + "/addInfoByUserId"
}
export class BalanceQuery extends Base {
    path = api + "/payQuery"
}

// 后台，支付撤销
export class BalancePayCancel extends Base {
    path = api + "/payCancel"
}

// 后台，支付退款
export class BalancePayRefund extends Base {
    path = api + "/payRefund"
}

// 取消罚款
export class BalanceCancelFines extends Base {
    path = api + "/cancelFines"
}

// 导出月结Execl
export class BalanceLogExportStatement extends Base {
    path = api + "/exportStatement"
}
